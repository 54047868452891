import React, {useState, useRef, useEffect, Fragment} from "react"
import * as Util from "../helpers/util.js"
import { Link, useNavigate } from "react-router-dom";

import {
    DocumentTextIcon, Square3Stack3DIcon, TagIcon
} from "@heroicons/react/24/outline";
import { useStore } from "../store";
import { Pagination } from "../components/pagination";
import {Spinner} from "../components/spinner";
import * as Feed from "../helpers/feed.js";
import { StateTitle } from "../components/state_title";


const ITEMS_PER_PAGE = 200

export const Activity = props => {
    const {search, government_uid, governments, session_uid, sessions, onGovernmentChange, onPolicyChange, onSessionChange, showToast} = props

    const { cache, setCache } = useStore( x => x )

    const [state, setState] = useState({
        page: 1,
        history: {},
        dates: [],
        humans: [],
        quick: true,
    })
    const { page, history, dates, humans, quick } = state

    const tracker = useRef();

    useEffect(() => {
        Util.fetch_js( '/api/human/list/', {}, js => {
            setState( prev => ({ ...prev,
                humans: js.humans,
            }) )
        }, showToast )
    }, [])

    useEffect(() => {
        if ( government_uid === null ) {
            return
        }

        tracker.current = { government_uid, session_uid }

        //Any government change, we need to update the document
        const payload = { government_uid, session_uid }
        if ( quick ) {
            payload.limit = ITEMS_PER_PAGE
        }
        Util.fetch_js('/api/document/history/', payload,
            js => {
                const { history, dates } = js

                //Block a slow load where users change states
                if ( tracker.current.government_uid !== js.government_uid) {
                    return
                }
                if ( tracker.current.session_uid !== null && tracker.current.session_uid !== js.session_uid) {
                    return
                }

                //setCache({ activity: { government_uid, session_uid }})
                //console.log(history)
                setState(prev => {
                    return ({ ...prev,
                        history,
                        dates,
                        quick: false,
                    })
                })
            }, showToast)

    }, [government_uid, session_uid, quick])

    const handlePaginationPage = (page) => {
        Util.scrollToTop()
        setState(prev => ({...prev, page}))
    }

    const handleGovernmentChange = uid => {
        const government = governments.find(x => x.uid === uid)
        if (government !== undefined && government !== null) {
            onGovernmentChange({target: {name: 'government_uid', value: government.uid}})
            setState(prev => ({...prev, quick: true}))
        }
    }

    const handleSessionChange = name => {
        const session = sessions.find(x => x.name === name)
        if (session !== undefined && session !== null) {
            onSessionChange({target: {name: 'session_uid', value: session.uid}})
            setState(prev => ({...prev, quick: true}))
        }
    }

    const filterSearch = (log) => {
        const search = props.search.toLowerCase()
        return search === '' ||
               log.document_code.toLowerCase().indexOf(search) >= 0 ||
               log.message.toLowerCase().indexOf(search) >= 0 ||
               log.type.toLowerCase().indexOf(search) >= 0
    }

    const possible_total = Object.values(history).reduce((sum, array) => sum + array.filter(x => filterSearch(x)).length, 0)

    let filtered_count = 0
    const filtered_history = {}
    let filtered_offset = (page - 1) * ITEMS_PER_PAGE
    for ( let i = 0; i < dates.length; i++ ) {
        const d = dates[i]
        const fh = history[d].filter(x => filterSearch(x))
        filtered_offset -= fh.length
        if ( filtered_offset > 0 ) {
            continue
        }

        //Add the data!
        filtered_history[d] = history[d].filter(x => filterSearch(x))
        filtered_count += filtered_history[d].length
        if ( filtered_count >= ITEMS_PER_PAGE ) {
            break
        }
    }
    const filtered_dates = dates.filter( d => d in filtered_history && filtered_history[d].length > 0 )

    const government = governments.find(x => x.uid === government_uid) || {name: 'None', is_tenant: false}
    const session = sessions.find( x => x.uid === session_uid ) || { name: 'None' }

    return (
        <div>
            <StateTitle
                title="Legislation"
                subtitle="Investigate the legislation that is currently in effect in your area."
                className="pl-4 sm:pr-4 pb-12"
                session_header="Session"
                session_text={session.name}
                session_list={sessions.map( x => x.name )}
                state_header="Government"
                state_uid={government.uid}
                state_list={governments}
                onStateClick={handleGovernmentChange}
                onSessionClick={name => handleSessionChange( name )}
            />

            <div className="px-4 sm:px-6 lg:px-24">
                <div className="flow-root">
                    {dates.length === 0 &&
                        <div className="w-full flex flex-row justify-center">
                            <div className="flex flex-col items-center">
                                <Spinner className="mt-2 w-12 h-12 text-indigo-600"/>
                                <p className="mt-8 text-gray-500">Loading activity...</p>
                            </div>
                        </div>
                    }
                    {filtered_dates.map((date, dateIdx) => (
                        <ul role="list" key={`activity_data_${dateIdx}`}>
                            <div className="mb-4 sm:-mx-6 lg:-mx-8 font-semibold text-sm">
                                {date}
                            </div>
                            {Feed.buildActivity(filtered_history[date], humans, true).map((event, eventIdx) => (
                                <li key={`${date}_${eventIdx}`}>
                                    <div className="relative pb-8">
                                        {eventIdx !== event.length - 1 ? (<span
                                            className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                                            aria-hidden="true"/>) : null}
                                        {event.logs.length === 1 &&
                                            <div className="relative flex space-x-3">
                                                <div>
                                                <span className='h-8 w-8 bg-white rounded-full flex items-center justify-center ring-8 ring-white'>
                                                    {event.logs[0].icon}
                                                </span>
                                                </div>
                                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                    <div>
                                                        <div className="text-sm text-gray-500">
                                                            <Link to={`/document/${event.document_uid}`}
                                                                  className="font-semibold text-indigo-600">
                                                                {event.document_code}
                                                            </Link>
                                                            <p className="flex-auto py-0.5 leading-5 text-gray-500">
                                                                <span className="font-medium text-gray-900">
                                                                    {event.human.name}
                                                                </span>
                                                                {event.logs[0].message}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {event.logs.length > 1 &&
                                            <div className="relative flex space-x-3">
                                                <div>
                                                    <span className='h-8 w-8 bg-white rounded-full flex items-center justify-center ring-8 ring-white'>
                                                        <Square3Stack3DIcon
                                                            className="h-6 w-6 text-gray-600"
                                                            aria-hidden="true"/>
                                                    </span>
                                                </div>
                                                <div
                                                    className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                    <div>
                                                        <div className="text-sm text-gray-500">
                                                            <Link
                                                                to={`/document/${event.document_uid}`}
                                                                className="font-semibold text-indigo-600">
                                                                {event.document_code}
                                                            </Link>
                                                            <p className="flex-auto py-0.5 leading-5 text-gray-500">
                                                                <span
                                                                    className="font-medium text-gray-900">
                                                                    {event.human.name}
                                                                </span>
                                                                {event.message}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {event.logs.length > 1 && event.logs.map( ( log, logIdx ) => (
                                            <div key={`el_${eventIdx}_${logIdx}`} className="relative flex mt-0.5 space-x-3 ml-6">
                                                <div>
                                                    <span className='h-8 w-8 bg-white rounded-full flex items-center justify-center ring-8 ring-white'>
                                                        {log.icon}
                                                    </span>
                                                </div>
                                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                    <div>
                                                        <div className="text-sm text-gray-500">
                                                            <p className="flex-auto py-0.5 leading-5 text-gray-500">
                                                                <span className="font-medium text-gray-900">
                                                                    {event.human.name}
                                                                </span>
                                                                {log.message}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ))}
                </div>

                <Pagination
                    page={page}
                    per_page={ITEMS_PER_PAGE}
                    total={possible_total}
                    onPage={handlePaginationPage}
                />
            </div>
        </div>
    )
                }
