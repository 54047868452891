import React from "react";
import {Dropdown, DropdownItem} from "./dropdown";
import * as Util from "../helpers/util.js";


export const StateTitle = props => {
    const { title, subtitle,
            session_header, session_text, session_list,
            state_header, state_uid, state_list, children,
            onSessionClick, onStateClick } = props
    const cn = props.className || ''

    const cur_state = state_list.find( item => item.uid === state_uid ) || {}

    return (
        <div className={Util.classNames("mx-auto max-w-7xl flex flex-row justify-between", cn)}>
            {(title || subtitle) &&
            <div>
                <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                    {title}
                </h1>
                <p className="mt-2 max-w-xl text-sm text-gray-700 overflow-ellipsis line-clamp-1">
                    {subtitle}
                </p>
            </div>
            }

            <div className='flex items-center divide-x divide-gray-200'>
                {!cur_state.is_tenant && session_list.length > 0 && (
                <Dropdown
                    className="pr-4"
                    header={session_header}
                    value={session_text}
                    align="right">
                    {session_list.map( ( item, idx ) => (
                        <DropdownItem
                            key={`pri_${item}_${idx}`}
                            id="session"
                            name="session"
                            value={item}
                            active={session_text === item}
                            onClick={() => onSessionClick(item)}>
                            {item}
                        </DropdownItem>)
                    )}
                </Dropdown>
                )}

                {cur_state.is_tenant && children}

                <Dropdown
                    className="pl-4 sm:pr-4"
                    header={state_header}
                    value={cur_state?.name}
                    align="right">
                    {state_list.map( ( item, idx ) => (
                        <DropdownItem
                            key={`pri_${item.uid}_${idx}`}
                            id="state"
                            name="state"
                            value={item.name}
                            active={state_uid === item.uid}
                            onClick={() => onStateClick(item.uid)}>
                            {item.name}
                        </DropdownItem>)
                    )}
                </Dropdown>
            </div>
        </div>)
}
