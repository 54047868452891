import * as Util from '../helpers/util.js'
import {Fragment} from "react";
import {ARROW_DIRECTION} from "../helpers/consts";

export const PriorityComp = props => {
    const {priority} = props
    const className = props.className || ''

    return (
        <Fragment>
            <div
               className={Util.classNames("inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200", className)}>
                {ARROW_DIRECTION[priority.toLowerCase()]}
                {Util.capitalize(priority)}
            </div>
        </Fragment>
    )
}
