import React, {useState, useRef, useEffect, Fragment} from "react"
import * as Util from "../helpers/util.js"
import { Link } from "react-router-dom";

import {
    Dropdown, DropdownCheckbox,
    DropdownItem
} from "../components/dropdown";
import { PillChange, PillType, simpleValue } from "../components/pill_change";
import * as Consts from "../helpers/consts";
import { LegislationFilter } from "../components/legislation_filter";
import { Sortable } from "../components/sortable";
import { useStore } from "../store";
import { paginateArray, Pagination } from "../components/pagination";
import { ImportFileModal } from "../modals/import_file_modal";
import { EmptyList } from "../components/empty_list";
import { UserRatingModal } from "../modals/user_rating_modal";
import {ConfirmModal} from "../modals/confirm_modal";

import {
    BellIcon,
    BellAlertIcon,
    DocumentTextIcon,
    TagIcon,
    ArrowUpTrayIcon
} from "@heroicons/react/24/outline";
import {StarIcon} from "@heroicons/react/24/solid";
import {UserCircleIcon} from "@heroicons/react/24/outline";
import { StateTitle } from "../components/state_title";
import { SummaryRating } from "../components/summary_rating";
import {TutorialModal} from "../modals/tutorial_modal";


const ITEMS_PER_PAGE = 50

export const Landing = props => {
    const {search, government_uid, governments, session_uid, sessions, policy_uid, policies, onGovernmentChange, onPolicyChange, onSessionChange, showToast} = props

    const { cache, setCache } = useStore( x => x )

    const updateCache = (obj) => {
        const org = cache.landing || {}
        setCache({landing: { ...org, ...obj }})
    }

    const readCache = (key, def) => {
        if ('landing' in cache && cache.landing.government_uid === government_uid) {
            return cache.landing[key] || def
        }

        return def
    }

    const [state, setState] = useState({
        page: 1,
        documents: readCache( 'documents', [] ),
        ratings: {},
        tags: [],
        selected_tags: {},
        selected_colors: {},
        upload_idx: 0,
        show_upload: false,
        show_quick_tag: -1,
        show_user_rating: null,
        show_user_rating_uid: null,
        show_confirm_untrack: null,
    })
    const { page, documents, ratings, tags, selected_tags, selected_colors, upload_idx, show_upload, show_quick_tag, show_user_rating, show_user_rating_uid, show_confirm_untrack } = state

    const fileRef = useRef(null)

    const [sorting, setSorting] = useState({
        field: 'code',
        asc: false,
    })

    const raw_stats = [
        //{ name: 'Legislation', stat: 0 },
        { name: 'Affirming', stat: 0, change: 1, isGood: x => (x >= 0) },
        { name: 'Opposing', stat: 0, change: -1, isGood: x => (x >= 0) },
    ]

    useEffect(() => {
        Util.fetch_js('/api/tags/list/', {},
            js => {
                setState(prev => ({ ...prev, tags: js.tags }))
            }, showToast)
    }, [])

    useEffect(() => {
        if ( government_uid === null ) {
            return
        }

        //Any government change, we need to update the document
        Util.fetch_js('/api/document/list/', { government_uid, session_uid },
            js => {
                // Ensure the tags are sorted so they dont jump around
                js.documents.forEach( d => {
                    d.tags.sort( (a, b) => a.name.localeCompare(b.name) )
                })

                setCache({ landing: { government_uid, documents: js.documents }})
                setState(prev => ({
                    ...prev,
                    page: 1,
                    documents: js.documents,
                }))
            }, showToast)

    }, [government_uid, session_uid, upload_idx])

    useEffect(() => {
        if ( documents.length === 0 || government_uid === null || government_uid === undefined ||
             policy_uid === null || policy_uid === undefined ) {
            return
        }

        /*
        //Nest to grab the ratings
        Util.fetch_js('/api/government/ratings/', { government_uid, session_uid, policy_uid },
            js => {
                setState(prev => ({
                    ...prev,
                    ratings: js.ratings,
                }))
            }, showToast)
         */

        //Nest to grab the ratings
        Util.fetch_js('/api/government/assessments/', { government_uid, session_uid, policy_uid },
            js => {
                setState(prev => ({
                    ...prev,
                    ratings: js.ratings,
                }))
            }, showToast)

        //Update my overview
        /*
        Util.fetch_js('/api/government/overview/', { government_uid, policy_uid },
            js => {
                const { total, supported, against } = js
                const count = (supported + against)? (supported + against): 1
                const new_stats = [
                    { ...stats[0], stat: supported, total: count },
                    { ...stats[1], stat: against, total: -count },
                    { ...stats[2], stat: total },
                ]
                setStats( new_stats )
            }, showToast)
         */

    }, [documents, government_uid, session_uid, policy_uid])

    const handleDocumentClicked = (document) => {
        const documents = [...state.documents]
        const idx = documents.findIndex(x => x.uid === document.uid)
        if ( idx >= 0 ) {
            documents[idx] = {...document, is_read: true}
        }

        //Update docs and set the cache since we are naving away, set doc for quick load on otherside
        setState(prev => ({...prev, documents}))
        updateCache({documents})
        setCache({document})
    }

    const handleToggleNotify = (document) => {
        Util.fetch_js('/api/document/modify/', { document_uid: document.uid, notify: !document.notify },
            js => {
                const documents = [...state.documents]
                const idx = documents.findIndex(x => x.uid === js.document.uid)
                if ( idx >= 0 ) {
                    documents[idx] = js.document
                }

                //Update the documents
                setState(prev => ({ ...prev, documents }))
                showToast('Notification ' + (js.document.notify? 'enabled': 'disabled'), 'success')
            }, showToast)
    }

    const handleToggleTracking = (document, force) => {
        if ( !force && document.tracked ) {
            setState(prev => ({...prev,
                show_confirm_untrack: document,
            }))
            return
        }

        //Modify the documents
        const payload = {
            document_uid: document.uid,
            tracked: !document.tracked
        }
        if ( payload.tracked ) {
            payload.notify = true
        }
        Util.fetch_js('/api/document/modify/', payload,
            js => {
                const documents = [...state.documents]
                const idx = documents.findIndex(x => x.uid === js.document.uid)
                if ( idx >= 0 ) {
                    documents[idx] = js.document
                }

                //Update the documents
                setState(prev => ({ ...prev, documents }))
                showToast('Tracking ' + (js.document.tracked? 'enabled': 'disabled'), 'success')
            }, showToast)

        //Register the job to be tracked with this policy
        if (payload.tracked) {
            Util.fetch_js('/api/document/register_job/', { document_uid: document.uid, policy_uid },
                js => {
                }, showToast)
        }

        setState(prev => ({...prev, show_confirm_untrack: null}))
    }

    const handleUserRating = document => {
        setState( prev => ({
            ...prev,
            show_user_rating: document.user_rating || 0,
            show_user_rating_uid: document.uid,
        }))
    }

    const handleChangePri = (document) => {
        const pri_idx = Consts.PRIORITIES.indexOf( document.priority.toLowerCase() )
        const priority = Consts.PRIORITIES[(pri_idx + 1) % Consts.PRIORITIES.length]

        Util.fetch_js('/api/document/modify/', { document_uid: document.uid, priority },
            js => {
                js.document.tags.sort( (a, b) => a.name.localeCompare(b.name) )

                const documents = [...state.documents]
                const idx = documents.findIndex(x => x.uid === js.document.uid)
                if ( idx >= 0 ) {
                    documents[idx] = js.document
                }

                //Update the documents
                setState(prev => ({ ...prev, documents }))
                showToast('Priority updated to ' + Util.capitalize(js.document.priority), 'success')
            }, showToast)
    }

    const handleChangeTag = (document_uid, tag, checked) => {
        if ( checked ) {
            Util.fetch_js( '/api/document/tag_add/', { document_uid, tag_uid: tag.uid },
                js => {
                    const documents = [...state.documents]
                    const idx = documents.findIndex(x => x.uid === document_uid)
                    if ( idx >= 0 ) {
                        documents[idx] = js.document
                    }

                    //Update the documents
                    setState(prev => ({ ...prev, documents }))
                }, showToast )
        }
        else {
            Util.fetch_js( '/api/document/tag_remove/', { document_uid, tag_uid: tag.uid },
                js => {
                    const documents = [...state.documents]
                    const idx = documents.findIndex(x => x.uid === document_uid)
                    if ( idx >= 0 ) {
                        documents[idx] = js.document
                    }

                    //Update the documents
                    setState(prev => ({ ...prev, documents }))
                }, showToast )
        }
    }

    const handleChangeUserRating = (document_uid, user_rating) => {
        if ( user_rating === null ) {
            setState(prev => ({...prev,
                show_user_rating_uid: null,
                show_user_rating: null
            }))
            return
        }

        Util.fetch_js('/api/document/modify/', { document_uid, user_rating },
            js => {
                js.document.tags.sort( (a, b) => a.name.localeCompare(b.name) )

                const documents = [...state.documents]
                const idx = documents.findIndex(x => x.uid === js.document.uid)
                if ( idx >= 0 ) {
                    documents[idx] = js.document
                }

                //Update the documents
                setState(prev => ({ ...prev,
                    documents,
                }))
                showToast('User rating updated to ' + js.document.user_rating, 'success')
            }, showToast)

        setState(prev => ({...prev,
            show_user_rating_uid: null,
            show_user_rating: null
        }))
    }


    /*
    const handleChangeColor = (document) => {
        const idx = documents.findIndex(x => x.uid === document.uid)
        const col_idx = Consts.COLORS.indexOf( document.color.toLowerCase() )
        const color = Consts.COLORS[(col_idx + 1) % Consts.COLORS.length]

        Util.fetch_js('/api/document/modify/', { document_uid: document.uid, color },
            js => {
                js.document.tags.sort( (a, b) => a.name.localeCompare(b.name) )

                const documents = [...state.documents]
                const idx = documents.findIndex(x => x.uid === js.document.uid)
                if ( idx >= 0 ) {
                    documents[idx] = js.document
                }

                //Update the documents
                setState(prev => ({ ...prev, documents }))
                showToast('Color updated to ' + Util.capitalize(js.document.color), 'success')
            }, showToast)
    }
     */

    const handleSort = (_field) => {
        /*
        //The F? Odd I know, but we step through 4 states of sorting
        if ( _field === 'code' ) {
            if ( sorting.field === 'priority' ) {
                if ( !sorting.asc ) {
                    setSorting( { field: 'priority', asc: true } )
                }
                else {
                    setSorting( { field: 'code', asc: false } )
                }
            }
            else if ( sorting.field === 'code' ) {
                if ( !sorting.asc ) {
                    setSorting( { field: 'code', asc: true } )
                }
                else {
                    setSorting( { field: 'priority', asc: false } )
                }
            }
            //Starting state form a different field
            else {
                setSorting( { field: 'priority', asc: false } )
            }
        }
        else {
         */
            const field = _field.toLowerCase()
            const asc = (sorting.field === field) ? !sorting.asc : (field === 'date_on' || field === 'comb_rating')
            setSorting({ field, asc })
        //}
    }

    const handlePaginationPage = (page) => {
        setState(prev => ({...prev, page}))
    }

    const handleAddDocument = () => {
        setState(prev => ({...prev,
            show_upload: true
        }))
    }

    const handleImportFile = (file) => {
        Util.fetch_js( '/api/document/upload/', { government_uid, file },
            js => {
                showToast('PDF imported', "success")
                setState(prev => ({ ...prev,
                    upload_idx: prev.upload_idx + 1,
                    show_upload: false,
                }))
            },
            err => {
                fileRef.current.cancelUpload()
                showToast(err, "error")
            } )
    }

    const handleImportClose = () => {
        setState(prev => ({ ...prev,
            show_upload: false
        }))
    }

    const handleGovernmentChange = uid => {
        const government = governments.find(x => x.uid === uid)
        if (government !== undefined && government !== null) {
            onGovernmentChange({target: {name: 'government_uid', value: government.uid}})
        }
    }

    const handleSessionChange = name => {
        const session = sessions.find(x => x.name === name)
        if (session !== undefined && session !== null) {
            onSessionChange({target: {name: 'session_uid', value: session.uid}})
        }
    }

    const handleQuickTag = idx => {
        if ( idx === show_quick_tag ) {
            idx = -1
        }

        setState(prev => ({...prev,
            show_quick_tag: idx
        }))
    }

    const filterSearch = (document) => {
        //Check for colors
        if ( Object.keys(selected_colors).length > 0 ) {
            if ( !(document.color.toLowerCase() in selected_colors) ) {
                return false
            }
        }

        //Check for tags
        if ( Object.keys(selected_tags).length > 0 ) {
            if ( document.tags.filter(tag => tag.uid in selected_tags).length === 0 ) {
                return false
            }
        }

        const tags = document.tags.map(t => t.name).join(' ')

        const search = props.search.toLowerCase()
        return search === '' ||
               tags.toLowerCase().indexOf(search) >= 0 ||
               document.tenant_title.toLowerCase().indexOf(search) >= 0 ||
               document.title.toLowerCase().indexOf(search) >= 0 ||
               document.code.toLowerCase().indexOf(search) >= 0 ||
               document.status.toLowerCase().indexOf(search) >= 0 ||
               document.priority.toLowerCase().indexOf(search) >= 0 ||
               document.color.toLowerCase().indexOf(search) >= 0
    }

    const sortComb = ( x, ratings, asc ) => {
        if ( !(x.uid in ratings) ) {
            return (asc)? 100 : -100
        }

        let ret = 0
        if ( asc ) {
            ret = (ratings[x.uid].against[0] > 0)? ratings[x.uid].against[1] : ratings[x.uid].supported[1]
        }
        else {
            ret = (ratings[x.uid].supported[0] > 0)? ratings[x.uid].supported[1] : ratings[x.uid].against[1]
        }
        return (ret !== 0)? ret : (asc)? 100 : -100
    }

    const sortDocument = () => {
        if ( sorting.field === 'code' ) {
            return (!sorting.asc) ? (a, b) => a.code.localeCompare( b.code ) :
                                    (b, a) => a.code.localeCompare( b.code )
        }
        else if ( sorting.field === 'title' ) {
            return (!sorting.asc) ? (a, b) => a.title.localeCompare( b.title ) :
                                    (b, a) => a.title.localeCompare( b.title )
        }
        else if ( sorting.field === 'status' ) {
            return (!sorting.asc) ? (a, b) => a.status.localeCompare( b.status ) :
                                    (b, a) => a.status.localeCompare( b.status )
        }
        else if ( sorting.field === 'date_on' ) {
            return (!sorting.asc) ? (a, b) => a.date_on.localeCompare( b.date_on ) :
                                    (b, a) => a.date_on.localeCompare( b.date_on )
        }
        else if ( sorting.field === 'priority' ) {
            const pri = {}
            Consts.PRIORITIES.forEach( (p, idx) => {
                pri[Util.capitalize(p)] = (p !== 'none' || !sorting.asc) ? idx : -idx
                pri[p.toLowerCase()] = (p !== 'none' || !sorting.asc) ? idx : -idx
            })

            return (!sorting.asc) ? (a, b) => pri[a.priority] - pri[b.priority] :
                                    (b, a) => pri[a.priority] - pri[b.priority]
        }
        else if ( sorting.field === 'user_rating' ) {
            //Sort based on successful matches
            if ( sorting.asc ) {
                return (a, b) => {
                    const _a = ( a.user_rating != null ) ? a.user_rating : 100
                    const _b = ( b.user_rating != null ) ? b.user_rating : 100
                    return _a - _b
                }
            }
            //Sort based on unsuccessful matches
            else {
                return (a, b) => {
                    const _a = ( a.user_rating != null ) ? a.user_rating : -100
                    const _b = ( b.user_rating != null ) ? b.user_rating : -100
                    return _b - _a
                }
            }
        }
        else if ( sorting.field === 'rating' ) {
            //Sort based on successful matches
            if ( sorting.asc ) {
                return (a, b) => {
                    const _a = ( a.uid in ratings ) ? ratings[a.uid].supported[0] : -1
                    const _b = ( b.uid in ratings ) ? ratings[b.uid].supported[0] : -1
                    return _b - _a
                }
            }
            //Sort based on unsuccessful matches
            else {
                return (a, b) => {
                    const _a = ( a.uid in ratings ) ? ratings[a.uid].against[0] : -1
                    const _b = ( b.uid in ratings ) ? ratings[b.uid].against[0] : -1
                    return _b - _a
                }
            }
        }
        else if ( sorting.field === 'comb_rating' ) {
            //Sort based on successful matches
            if ( sorting.asc ) {
                return (a, b) => {
                    const _a = ( a.user_rating != null ) ? a.user_rating : sortComb( a, ratings, true )
                    const _b = ( b.user_rating != null ) ? b.user_rating : sortComb( b, ratings, true )
                    return _a - _b
                }
            }
            //Sort based on unsuccessful matches
            else {
                return (a, b) => {
                    const _a = ( a.user_rating != null ) ? a.user_rating : sortComb( a, ratings, false )
                    const _b = ( b.user_rating != null ) ? b.user_rating : sortComb( b, ratings, false )
                    return _b - _a
                }
            }
        }
    }

    const calcStats = (stats, documents, ratings) => {
        //const [total, ya, na] = [...stats]
        const [ya, na] = [...stats]

        //Reset my values
        ya.stat = ya.value = 0
        na.stat = na.value = 0

        //Go through all the documents and add up the stats
        documents.forEach( d => {
            if ( d.user_rating !== null && d.user_rating !== 0 ) {
                if ( d.user_rating > 0 ) {
                    ya.stat++
                    ya.value += d.user_rating
                }
                else {
                    na.stat++
                    na.value += d.user_rating
                }
            }
            else if ( d.uid in ratings ) {
                const rate = ratings[d.uid]
                ya.stat += rate.supported[0]
                na.stat += rate.against[0]

                ya.value += rate.supported[1]
                na.value += rate.against[1]
            }
        })
        //total.stat = documents.length
        //return [total, ya, na]

        return [ya, na]
    }

    const filtered_documents = documents.filter(x => filterSearch(x))
    filtered_documents.sort( sortDocument() )

    //Update the header stats based on filtering and searching
    const stats = calcStats(raw_stats, filtered_documents, ratings)

    const clean_documents = paginateArray(filtered_documents, page, ITEMS_PER_PAGE)

    const government = governments.find( x => x.uid === government_uid ) || { name: 'None', is_tenant: false }
    const session = sessions.find( x => x.uid === session_uid ) || { name: 'None' }

    return (<div>
        <StateTitle
            title="Legislation"
            subtitle="Investigate the legislation that is currently in effect in your area."
            className="pl-4 sm:pr-4 pb-12"
            session_header="Session"
            session_text={session.name}
            session_list={sessions.map(x => x.name)}
            state_header="Government"
            state_uid={government.uid}
            state_list={governments}
            onStateClick={handleGovernmentChange}
            onSessionClick={name => handleSessionChange(name)}>
            <button
                type="button"
                className="inline-flex mr-4 items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleAddDocument}>
                <ArrowUpTrayIcon className="-ml-0.5 mr-1.5 h-5 w-5"
                                 aria-hidden="true"/>
                Upload
            </button>
    </StateTitle>

    <LegislationFilter
        government_uid={government_uid}
        governments={governments}
        policy_uid={policy_uid}
            policies={policies}
            sessions={sessions}
            session_uid={session_uid}
            tags={tags}
            selected_tags={selected_tags}
            selected_colors={selected_colors}
            sorting={sorting}
            onSort={handleSort}
            onTagChange={selected_tags => setState( prev => ({
                ...prev, selected_tags
            }) )}
            onColorChange={selected_colors => setState( prev => ({
                ...prev,
                selected_colors
            }) )}
            /*onGovernmentChange={onGovernmentChange}*/
            onPolicyChange={onPolicyChange}
            onSessionChange={onSessionChange}
        />

        <SummaryRating
            className="mt-8"
            stats={stats}
            policies={policies}
            policy_uid={policy_uid}
            onPolicy={onPolicyChange}
        />

        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table
                            className="min-w-full border-separate border-spacing-0">
                            <thead>
                            <tr>
                                <th scope="col"
                                    className="py-3.5 text-left text-sm text-gray-900 border-b sm:pl-6 lg:pl-8">
                                    <div
                                        className="group inline-flex cursor-pointer"
                                        onClick={() => handleSort( 'code' )}>
                                        Code
                                        <Sortable
                                            show={sorting.field === 'code'}
                                            asc={sorting.asc}
                                        />
                                    </div>
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell border-b">
                                    <div
                                        className="group inline-flex cursor-pointer"
                                        onClick={() => handleSort( 'title' )}>
                                        Title
                                        <Sortable
                                            show={sorting.field === 'title'}
                                            asc={sorting.asc}
                                        />
                                    </div>
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell border-b">
                                    <div
                                        className="group inline-flex cursor-pointer"
                                        onClick={() => handleSort( 'priority' )}>
                                        Tags
                                        <Sortable
                                            show={sorting.field === 'priority'}
                                            asc={sorting.asc}
                                        />
                                    </div>
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell border-b">
                                    <div
                                        className="group inline-flex cursor-pointer"
                                        onClick={() => handleSort( 'status' )}>
                                        Status
                                        <Sortable
                                            show={sorting.field === 'status'}
                                            asc={sorting.asc}
                                        />
                                    </div>
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-b">
                                    <div className="group inline-flex cursor-pointer"
                                        onClick={() => handleSort( 'comb_rating' )}>
                                        Rating
                                        <Sortable
                                            show={sorting.field === 'comb_rating'}
                                            asc={sorting.asc}
                                        />
                                    </div>
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-b">
                                    <div className="group inline-flex cursor-pointer"
                                        onClick={() => handleSort( 'date_on' )}>
                                        Updated
                                        <Sortable
                                            show={sorting.field === 'date_on'}
                                            asc={sorting.asc}
                                        />
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {clean_documents.map( ( document, idx ) => (
                                <tr key={`document_tr_${idx}`}
                                    className={document.is_read ? 'font-medium' : 'font-semibold bg-gray-100'}>
                                    <td className={Util.classNames( idx !== clean_documents.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 lg:pl-8' )}>
                                        <div
                                            className="flex items-center space-x-3.5">
                                            <div className="cursor-pointer"
                                                 onClick={() => handleToggleTracking( document, false )}>
                                                {document.tracked ? <StarIcon
                                                        className="h-5 w-5 text-yellow-500"/> :
                                                    <StarIcon
                                                        className="h-5 w-5 text-gray-300"/>}
                                            </div>
                                            <div className="cursor-pointer">
                                                <div className="cursor-pointer"
                                                     onClick={() => handleToggleNotify( document )}>
                                                    {document.notify ?
                                                        <BellAlertIcon
                                                            className="h-5 w-5 text-indigo-600"/> :
                                                        <BellIcon
                                                            className="h-5 w-5 text-gray-300"/>}
                                                </div>
                                            </div>
                                            <Link
                                                to={`/document/${document.uid}`}
                                                className="text-indigo-600 hover:text-indigo-900"
                                                onClick={() => handleDocumentClicked( document )}>
                                                {document.code}
                                            </Link>
                                            {/*
                                            <div className="cursor-pointer"
                                                 onClick={() => handleChangeColor(document)}>
                                                {Consts.CircleColor(document.color.toLowerCase())}
                                            </div>
                                            */}
                                        </div>
                                    </td>
                                    <td className={Util.classNames( idx !== clean_documents.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-wrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell' )}>
                                        {document.tenant_title !== '' ? document.tenant_title : document.title}
                                    </td>
                                    <td className={Util.classNames( idx !== clean_documents.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-wrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell' )}>
                                        <div
                                            className="inline-flex flex-row space-x-1 py-1 cursor-pointer"
                                            onClick={() => handleQuickTag( idx )}>
                                            {(document.tags.length === 0 || document.priority.toLowerCase() !== 'none') && Consts.ARROW_DIRECTION[document.priority.toLowerCase()]}
                                            {document.tags.map( ( tag, idx ) => (
                                                <p key={`tag_list_${idx}`}
                                                   className={Util.classNames( Consts.TextColor( tag.color.toLowerCase() ), )}>
                                                    {`${tag.name}${idx + 1 < document.tags.length ? ', ' : ''}`}
                                                </p>) )}
                                        </div>

                                        {show_quick_tag === idx && <Dropdown
                                            show={show_quick_tag === idx}
                                            header="Tags"
                                            align="right"
                                            onClose={() => handleQuickTag( -1 )}>
                                            {tags.length === 0 && <DropdownItem
                                                icon={TagIcon}>
                                                No tags available
                                            </DropdownItem>}
                                            {tags.map( ( tag, idx ) => (
                                                <DropdownCheckbox
                                                    key={`tag_${idx}`}
                                                    name={`quick_tag_${idx}`}
                                                    checked={document.tags.map( t => t.uid ).includes( tag.uid )}
                                                    onChange={e => handleChangeTag( document.uid, tag, e.target.checked )}>
                                                    <p className={Util.classNames( Consts.TextColor( tag.color.toLowerCase() ), )}>
                                                        {tag.name}
                                                    </p>
                                                </DropdownCheckbox>) )}
                                        </Dropdown>}
                                    </td>
                                    <td className={Util.classNames( idx !== clean_documents.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-wrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell' )}>
                                        {document.status}
                                    </td>
                                    <td className={Util.classNames( idx !== clean_documents.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500' )}>
                                        {document.user_rating == null && document.uid in ratings && <>
                                            <PillChange
                                                className="ml-1"
                                                {...simpleValue( ratings[document.uid].supported[1] )}
                                                onClick={() => handleUserRating( document )}
                                            />
                                            <PillChange
                                                className="ml-1"
                                                {...simpleValue( ratings[document.uid].against[1] )}
                                                onClick={() => handleUserRating( document )}
                                            />
                                        </>}
                                        {document.user_rating == null && !(document.uid in ratings) &&
                                            <PillChange
                                                value={'N/A'}
                                                change={0}
                                                type={PillType.NA}
                                                onClick={() => handleUserRating( document )}
                                            />}
                                        {document.user_rating !== null && <div
                                            className="inline-flex gap-x-2 items-center cursor-pointer"
                                            onClick={() => handleUserRating( document )}>
                                            <UserCircleIcon
                                                className="flex-shrink-0 h-5 w-5 text-gray-500"/>
                                            <PillChange
                                                {...simpleValue( document.user_rating )}
                                            />
                                        </div>}
                                    </td>
                                    <td className={Util.classNames( idx !== clean_documents.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-wrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell' )}>
                                        {document.date_on}
                                    </td>
                                </tr>) )}
                            </tbody>
                        </table>

                        {false && clean_documents.length > 0 && government.is_tenant &&
                            <div className="mt-8">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={handleAddDocument}>
                                    <ArrowUpTrayIcon className="-ml-0.5 mr-1.5 h-5 w-5"
                                              aria-hidden="true"/>
                                    Upload Document
                                </button>
                            </div>
                        }

                        {clean_documents.length === 0 && government.is_tenant &&
                            <EmptyList
                                icon={DocumentTextIcon}
                                title="No content"
                                />
                        }

                        <ImportFileModal
                            title="Upload PDF / Docx"
                            accept=".pdf, .docx"
                            ref={fileRef}
                            open={show_upload}
                            load_on_start={true}
                            onUpload={handleImportFile}
                            onClose={handleImportClose}
                            showToast={showToast}
                        />

                        <UserRatingModal
                            uid={show_user_rating_uid}
                            open={show_user_rating !== null}
                            value={show_user_rating}
                            onClose={handleChangeUserRating}
                            showToast={showToast}
                        />

                        <ConfirmModal
                            open={show_confirm_untrack !== null}
                            title="Are you sure?"
                            message={`Are you sure you want to stop tracking ${show_confirm_untrack?.code || ''}?`}
                            danger={true}
                            confirmBtn="Yes"
                            onConfirm={() => handleToggleTracking(show_confirm_untrack, true)}
                            onClose={() => setState(prev => ({...prev, show_confirm_untrack: null}))}
                        />

                        <Pagination
                            page={page}
                            per_page={ITEMS_PER_PAGE}
                            total={filtered_documents.length}
                            onPage={handlePaginationPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
