import * as Util from '../helpers/util.js'
import {Fragment} from "react";
import {CircleColor, TextColor} from "../helpers/consts";

export const TagComp = props => {
    const {name, color, value} = props
    const className = props.className || ''

    return (
        <Fragment>
            <div
               className={Util.classNames("inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200", className)}>
                {CircleColor(color, 'w-2 h-2')}
                {value}
            </div>
        </Fragment>
    )
}
